/* globals evil */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'prosemirror'
  ]

  disconnect () {
    if (this.currentRequest != null) {
      this.currentRequest.abort()
      this.currentRequest = null
    }
  }

  focus () {
    const pmController = this.application.getControllerForElementAndIdentifier(this.prosemirrorTarget, 'prosemirror')
    pmController.focus()
  }

  prependMention (username) {
    const pmController = this.application.getControllerForElementAndIdentifier(this.prosemirrorTarget, 'prosemirror')
    pmController.prependMention(username)
  }

  createComment () {
    const url = this.element.dataset.url

    if (!url) throw Error('URL is not provided')

    if (this.currentRequest) {
      return
    }

    const pmController = this.application.getControllerForElementAndIdentifier(this.prosemirrorTarget, 'prosemirror')

    this.currentRequest = $.rails.ajax({
      url: url,
      method: 'POST',
      data: {
        'comment[body]': pmController.getHTML()
      },
      dataType: 'html',
      success: (responseText) => {
        const newContent = $(responseText)

        $(this.element.dataset.comments).append(newContent)

        pmController.reset()

        evil.block.vitalize(newContent)
      },
      complete: () => {
        this.currentRequest = null
      }
    })
  }
}
