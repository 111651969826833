/* globals evil */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['body', 'actions', 'form', 'confirmDelete', 'popoverActions', 'prosemirror']

  disconnect () {
    if (this.currentRequest != null) {
      this.currentRequest.abort()
      this.currentRequest = null
    }
  }

  prosemirrorTargetConnected (el) {
    const pmController = this.application.getControllerForElementAndIdentifier(el, 'prosemirror')
    pmController.focus()
  }

  showEditForm (event) {
    const button = event.currentTarget
    const url = button.dataset.url

    if (!url) throw Error('URL is not provided')

    if (this.currentRequest) {
      return
    }

    this.currentRequest = $.rails.ajax({
      url: url,
      dataType: 'html',
      success: (responseText) => {
        const newContent = $(responseText)

        $([this.bodyTarget, this.actionsTarget]).hide()
        $(this.element).append(newContent)

        evil.block.vitalize(newContent)
      },
      complete: () => {
        this.currentRequest = null

        button.dispatchEvent(new CustomEvent('loader:reset'))
      }
    })
  }

  cancelEdit () {
    $([this.bodyTarget, this.actionsTarget]).show()
    $(this.formTarget).remove()
  }

  updateComment () {
    const url = this.formTarget.dataset.url

    if (!url) throw Error('URL is not provided')

    if (this.currentRequest) {
      return
    }

    const pmController = this.application.getControllerForElementAndIdentifier(this.prosemirrorTarget, 'prosemirror')

    this.currentRequest = $.rails.ajax({
      url: url,
      method: 'PATCH',
      data: {
        'comment[body]': pmController.getHTML()
      },
      dataType: 'html',
      success: (responseText) => {
        const newContent = $(responseText)

        $(this.element).replaceWith(newContent)

        evil.block.vitalize(newContent)
      },
      complete: () => {
        this.currentRequest = null
      }
    })
  }

  reply ({ params }) {
    const el = document.querySelector(params.replyForm)
    el.classList.remove('is-hidden')

    setTimeout(() => {
      const commentFormController = this.application.getControllerForElementAndIdentifier(el, 'comment-form')

      if (params.username) {
        commentFormController.prependMention(params.username)
      }

      commentFormController.focus()
      commentFormController.element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 0)
  }
}
